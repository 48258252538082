export const formFieldMixin = {
  inheritAttrs: false,

  props: {
    label: { type: String, default: "" },
    value: [String, Number],
    isInvalid: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    updateValue(event) {
      this.$emit("input", event.target.value);
    }
  }
};
