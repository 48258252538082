import axios from "axios";

export default {
  sendPhoneConfirmationCode(phone, web = null) {
    return axios.get(`/resend-confirmation-code/${phone}${web ? "/web" : ""}`);
  },

  confirmPhoneNumber(payload) {
    return axios.post("/confirm-phone-number", payload);
  },

  getUser(id) {
    return axios.get("/users/" + id);
  },

  updateUser(user) {
    return axios.put("/users/" + user.id, user);
  },

  updateUserPreference(payload) {
    return axios.post("/users/newsletters", payload);
  },

  searchUserByEmail(email) {
    return axios.post("/users/list", { search: true, email: email });
  },

  getMovements(user_id, page = 1, paginate = 10) {
    return axios.get(
      `/users/${user_id}/mouvements?page=${page}&paginate=${paginate}`
    );
  },

  getNotifications(page = 1) {
    return axios.get(`/users/notifications/all?page=${page}`);
  },

  disableUserAccount(user_id, reason) {
    return axios.post(`/users/${user_id}/activation/1`, { motif: reason });
  }
};
