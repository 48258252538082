<template>
  <div>
    <!-- [ navigation menu ] start -->
    <VerticalMenu />
    <!-- [ navigation menu ] end -->

    <!-- [ Header ] start -->
    <Header />
    <!-- [ Header ] end -->

    <!-- [ Main Content ] start -->
    <div class="pcoded-main-container bacground-color">
      <div class="pcoded-wrapper">
        <div class="pcoded-content pl-xs-0 pr-xs-0 pt-xs-0 pt-4 pt-sm-4">
          <div class="pcoded-inner-content ">
            <BreadCrumb class="d-none d-sm-block" :prev-route="prevRoute" />
            <div class="main-body">
              <div class="page-wrapper">
                <notification-container />
                <transition name="slide-fade" mode="out-in">
                  <router-view />
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
    <!-- [ Main Content ] end -->
  </div>
</template>

<script>
import VerticalMenu from "./VerticalMenu";
import Header from "./Header";
import Footer from "./Footer";
import BreadCrumb from "./BreadCrumb";
import NotificationContainer from "../notification/NotificationContainer";
import Vue from "vue";
import { mapGetters } from "vuex";
import { getUserSession } from "../../helpers/utils";

export default {
  name: "Account",

  components: {
    NotificationContainer,
    BreadCrumb,
    Footer,
    Header,
    VerticalMenu
  },

  data() {
    return {
      prevRoute: null,
      timeout: null
    };
  },

  watch: {
    $route(current, prev) {
      if (prev) {
        if (current.name !== prev.name) {
          const baseName = prev.name.split(".");
          if (RegExp(baseName[0] + "*").test(this.$route.name))
            this.prevRoute = prev;
          else this.prevRoute = null;
        } else this.prevRoute = null;
      } else this.prevRoute = null;
    }
  },

  metaInfo() {
    return {
      title: this.$t(`menu.${this.$route.name}.title`)
    };
  },

  created() {
    this.$store.dispatch("auth/getCurrentUser");
    this.prevRoute = null;
  },

  computed: {
    ...mapGetters("auth", ["userId"])
  },

  mounted() {
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      (async () => {
        await Vue.loadScript(`${window.location.origin}/assets/js/pcoded.js`);
      })();
      this.initiateEchoSocket();
    }, 1500);
  },

  methods: {
    initiateEchoSocket() {
      const userData = getUserSession("current_user_credentials");
      if (userData)
        this.$echo.connector.options.auth.headers[
          "Authorization"
        ] = `Bearer ${userData.token}`;
      this.$echo
        .private(`App.Models.User.${this.userId}`)
        .notification(notification => {
          this.$store.dispatch(
            "auth/receiveBroadcastNotification",
            notification
          );
        });
    }
  }
};
</script>

<style>
.bacground-color {
  background-color: rgb(241, 245, 246);
}
.custom-panel {
}
</style>
