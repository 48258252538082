<template>
  <div :class="label ? 'form-group' : ''">
    <label v-if="label">{{ label }}</label>
    <input
      v-on="listeners"
      class="form-control"
      v-bind="$attrs"
      :value="value"
      :class="{ 'is-invalid': isInvalid }"
      @input="updateValue"
    />
    <slot />
  </div>
</template>

<script>
import { formFieldMixin } from "../../mixins/formFieldMixin";
export default {
  name: "BaseInput",
  mixins: [formFieldMixin],
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue
      };
    }
  }
};
</script>

<style scoped></style>
