<template>
  <div>
    <button v-on="$listeners" v-bind="$attrs" class="btn" :class="buttonClass">
      <slot />
      <span
        v-if="showLoading && loading"
        class="spinner-grow spinner-grow-sm m-l-5"
        role="status"
      ></span>
    </button>
  </div>
</template>

<script>
export default {
  name: "BaseButton",
  inheritAttrs: false,
  props: {
    buttonClass: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    showLoading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped></style>
