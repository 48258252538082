import { typeServiceCode, typeTicketCode } from "./constants";
import Swal from "sweetalert2";

export function getVerifyEmailRoute() {
  return `${location.protocol}//${location.host}/email/verify/{token}`;
}

export function getPasswordResetRoute() {
  return `${location.protocol}//${location.host}/password/reset/{token}?email={email}`;
}

export function getServiceOperation(users_service) {
  switch (users_service.service.type_service.code) {
    case typeServiceCode.voyage:
      try {
        const result = JSON.parse(users_service.reference);
        return {
          name: result.name_agency,
          identify: result.standing_trip
            ? result.standing_trip.toUpperCase()
            : result.standing_trip,
          travel: result
        };
      } catch (e) {
        return {
          name: users_service.service.type_service.label,
          identify: users_service.reference
        };
      }
    case typeServiceCode.sell_card:
      return {
        name: "Achât VISA",
        identify: users_service.reference
      };
    case typeServiceCode.charge_card: {
      try {
        const result = JSON.parse(users_service.reference);
        return {
          name: result.bank,
          identify: result.ref,
          creditCard: result
        };
      } catch (e) {
        return {
          name: users_service.service.type_service.label,
          identify: users_service.reference
        };
      }
    }
    default:
      return {
        name: users_service.service.type_service.label,
        identify: users_service.reference
      };
  }
}

export function getServiceLogo(users_service) {
  if (users_service.service.icon) return users_service.service.icon;
  else {
    if (users_service.service.type_service.code === typeServiceCode.voyage) {
      const result = JSON.parse(users_service.reference);
      return result.logo_agency;
    }
    return users_service.service.type_service.image;
  }
}

export function getTypeServiceName(type_service) {
  switch (type_service.code) {
    case typeServiceCode.sell_card:
      return "Achat VISA";
    case typeServiceCode.charge_card:
      return "Recharge VISA";
    default:
      return type_service.label;
  }
}

export function travelTimeIsNight(voyage) {
  let dateVoyage = new Date(voyage.date + " " + voyage.departure_time);
  let start = new Date(voyage.date + " 04:00 am");
  let end = new Date(voyage.date + " 06:00 pm");
  return dateVoyage < start || dateVoyage >= end;
}

export function phoneLengthByCallingCode(code) {
  switch (code) {
    case "237":
    case "221":
      return 9;
    default:
      return 8;
  }
}

export function confirmation(
  title = "Confirmez-vous l'opération ?",
  message = null,
  ok = "Oui Confirmer",
  nok = "Non annuler"
) {
  return Swal.fire({
    title: title,
    text: message,
    confirmButtonColor: "#32849c",
    cancelButtonColor: "#6c757d",
    reverseButtons: true,
    showCancelButton: true,
    confirmButtonText: `<i class="fa fa-thumbs-up m-r-5"></i> ${ok}`,
    cancelButtonText: nok
  }).then(result => {
    return result.isConfirmed;
  });
}

export function getModelTypeByCode(code) {
  switch (code) {
    case typeTicketCode.deposit:
      return "Depot";
    case typeTicketCode.withdraw:
      return "Retrait";
    case typeTicketCode.payment:
      return "Payment";
    default:
      return null;
  }
}

export function setUserSession(key, userData) {
  let now = new Date();
  if (userData.remember) now.setHours(now.getHours() + 72);
  else now.setHours(now.getHours() + 12);
  const item = {
    value: userData,
    expiry: now.getTime()
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getUserSession(key) {
  try {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }

    return item.value;
  } catch (e) {
    localStorage.removeItem(key);
    return null;
  }
}
