import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "nprogress/nprogress.css";
import numeral from "numeral";

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import DateFilter from "./filters/date";
import TruncateFilter from "./filters/truncate";
Vue.filter("date", DateFilter);
Vue.filter("truncate", TruncateFilter);

import numFormat from "vue-filter-number-format";
Vue.filter("numFormat", numFormat(numeral));

import VueContentPlaceholders from "vue-content-placeholders";
Vue.use(VueContentPlaceholders);

import VueIziToast from "vue-izitoast";
import "izitoast/dist/css/iziToast.css";
Vue.use(VueIziToast);

import VueClipboards from "vue-clipboards";
Vue.use(VueClipboards);

import VueMoment from "vue-moment";
const moment = require("moment");
require("moment/locale/fr");
Vue.use(VueMoment, { moment });

import VueMeta from "vue-meta";
Vue.use(VueMeta);

import vuexI18n from "vuex-i18n";
import fr_FR from "./langs/fr";
import en_EN from "./langs/en";
import { getUserSession } from "./helpers/utils";
const config = {
  moduleName: "i18n",
  translateFilterName: "t",
  onTranslationNotFound(locale, key) {
    console.warn(`i18n :: Key '${key}' not found for locale '${locale}'`);
  }
};
Vue.use(vuexI18n.plugin, store, config);
Vue.i18n.add("fr", fr_FR);
Vue.i18n.add("en", en_EN);
Vue.i18n.set("fr");

require("./config/global");
require("./config/assets");
require("./config/axios");
require("./config/echo");

Vue.config.productionTip = process.env.NODE_ENV !== "production";

new Vue({
  router,
  store,
  created() {
    const userData = getUserSession("h_user_credentials");
    if (userData) {
      this.$store.commit("auth/SET_USER_TOKEN", userData);
    }
  },
  render: h => h(App)
}).$mount("#app");
