<template>
  <date-picker
    v-on="listeners"
    style="width: 100%"
    :input-class="'form-control' + (isInvalid ? ' is-invalid' : '')"
    :value="value"
    :default-value="customDataValue"
    v-bind="$attrs"
    :format="format"
    :range="range"
    :lang="$moment.locale()"
    :disabled-date="disableDate"
  ></date-picker>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/en";
import "vue2-datepicker/locale/fr";

export default {
  name: "BaseDatepicker",
  inheritAttrs: false,
  components: { DatePicker },
  props: {
    value: [String, Date, Array],
    format: {
      type: String,
      default: "DD-MM-YYYY"
    },
    notAfter: {
      type: [Date, String],
      default: null
    },
    notBefore: {
      type: [Date, String],
      default: null
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    range: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue
      };
    },
    customDataValue() {
      return this.notAfter
        ? this.notAfter
        : this.notBefore
        ? this.notBefore
        : null;
    }
  },
  methods: {
    disableDate(date) {
      let result = false;
      if (this.notBefore) result = date < new Date(this.notBefore);
      if (this.notAfter) result = date > new Date(this.notAfter);
      return result;
    },
    updateValue(event) {
      this.$emit("input", event);
    }
  }
};
</script>

<style scoped></style>
