<template>
  <div class="alert alert-dismissible" :class="alertClass">
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
    <strong class="d-block font-weight-bold" v-if="title">{{ title }}</strong>
    {{ message }}
  </div>
</template>

<script>
export default {
  name: "BaseAlert",
  props: {
    message: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: null
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    alertClass() {
      return "alert-bwallet-" + this.type;
    }
  }
};
</script>

<style scoped></style>
