import store from "@/store";

const ticket = [
  {
    path: "/",
    redirect: { name: "ticket.list" }
  },
  {
    path: "list/:type_tickets_id?",
    name: "ticket.list",
    component: () => import("../views/ticket/TicketList"),
    beforeEnter(routeTo, routeFrom, next) {
      store.commit(
        "assistance/UPDATE_FIELD_TYPE_TICKETS_ID",
        routeTo.params.type_tickets_id ? routeTo.params.type_tickets_id : null
      );
      next();
    }
  },
  {
    path: ":type_tickets_id?/details/:reference",
    name: "ticket.detail",
    component: () => import("../views/ticket/TicketDetails"),
    children: [
      {
        path: "manage-messages",
        name: "ticket.detail.message",
        component: () => import("../views/ticket/TicketSavedMessage")
      }
    ]
  },
  {
    path: ":type_tickets_id?/details/:reference/users/:users_id",
    name: "ticket.user.detail",
    component: () => import("../views/profile/UserMovements")
  }
];

export default ticket;
