import Vue from "vue";
window.io = require("socket.io-client");
import Echo from "laravel-echo";
import { getUserSession } from "../helpers/utils";

const url =
  process.env.NODE_ENV !== "production"
    ? "http://127.0.0.1"
    : "https://api.be-wallet.net";
export var echo_instance = new Echo({
  broadcaster: "socket.io",
  host: `${url}:6001`
});

const userData = getUserSession("current_user_credentials");
if (userData)
  echo_instance.connector.options.auth.headers[
    "Authorization"
  ] = `Bearer ${userData.token}`;

Vue.prototype.$echo = echo_instance;
