<template>
  <footer class="text-center">
    <small class="footer-text text-muted p-3">
      © 2018 - {{ date.getFullYear() }} <b>BeWallet</b> by Open solutions SARL.
      Tous droits reservés.
      <em>
        Développé par
        <a href="https://lab2view.com" target="_blank" class="text-muted">
          Lab2View
        </a>
      </em>
    </small>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      date: new Date()
    };
  }
};
</script>

<style scoped></style>
