import { mapGetters } from "vuex";
import { getUserSession, setUserSession } from "../helpers/utils";
export const authComputed = {
  ...mapGetters("auth", ["loggedIn"])
};

export function editCurrentUser(user) {
  let userData = getUserSession("h_user_credentials");
  userData.user = user;
  setUserSession("h_user_credentials", userData);
}
