import Vue from "vue";
import Vuex from "vuex";
import CountryService from "../services/CountryService";
import auth from "./modules/auth";
import notification from "./modules/notification";
import assistance from "./modules/assistance";
import user from "./modules/user";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";
export default new Vuex.Store({
  state: {
    countries: [],
    echo_connect: true,
    routerLoading: false
  },
  mutations: {
    SET_ROUTER_LOADING(state, routerLoading) {
      state.routerLoading = routerLoading;
    },
    SET_COUNTRIES(state, countries) {
      state.countries = countries;
    },
    CHANGE_ECHO_CONNECT(state, status) {
      state.echo_connect = status;
    }
  },
  actions: {
    checkEndMaintenanceMode() {
      return CountryService.checkEndOfMaintenanceMode().then(({ data }) => {
        return data;
      });
    },
    getCountries({ commit, state }) {
      if (state.countries.length > 0) return state.countries;
      else {
        return CountryService.getCountries().then(response => {
          commit("SET_COUNTRIES", response.data);
        });
      }
    },
    changeEchoConnexionState({ commit }, status) {
      commit("CHANGE_ECHO_CONNECT", status);
    },
    startRouterLoading({ commit }) {
      commit("SET_ROUTER_LOADING", true);
    },
    stopRouterLoading({ commit }) {
      commit("SET_ROUTER_LOADING", false);
    }
  },
  modules: {
    auth,
    notification,
    assistance,
    user
  },
  strict: debug
});
