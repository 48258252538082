const profile = [
  {
    path: "/",
    redirect: { name: "formProfile" }
  },
  {
    path: "edit",
    name: "profile",
    component: () => import("../views/profile/FormProfile")
  },
  {
    path: "change-password",
    name: "formPassword",
    component: () => import("../views/profile/FormPassword")
  }
];

export default profile;
