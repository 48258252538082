<template>
  <div class="card-footer pt-0 pb-0 bg-light">
    <div class="row mt-2 m-r-10 pt-1 align-items-center">
      <div class="col-md-auto ml-3">
        <div class="input-group">
          <label for="ticketFieldPage" class="m-r-5">Page </label>
          <select
            id="ticketFieldPage"
            class="form-control rounded"
            :value="current_page"
            @change="$emit('page', $event.target.value)"
          >
            <option v-for="i in last_page" :key="`page-${i}`" :value="i">
              {{ i }}
            </option>
          </select>
        </div>
      </div>
      <div class="col"></div>
      <div class="col-md-auto">
        <BasePagination
          :current_page="current_page"
          :last_page="last_page"
          @page="$emit('page', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseCardTableFooter",
  props: {
    current_page: {
      type: Number,
      default: null
    },
    last_page: {
      type: Number,
      default: null
    }
  }
};
</script>

<style scoped></style>
