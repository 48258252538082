<template>
  <div>
    <div class="card">
      <div class="row">
        <div class="col-md-12 col-xl-9">
          <div class="card-block px-0 py-3">
            <pie-count-stats />
          </div>
        </div>
        <div class="col-md-6 col-xl-3 border-left">
          <div class="card-block border-bottom">
            <div class="row  align-items-center">
              <div class="col-8">
                <h2 class=" m-0">19</h2>
                <span class="text-muted">Last Week 70%</span>
              </div>
              <div class="col-4 text-right">
                <h5 class="text-c-red f-w-400">25%</h5>
              </div>
            </div>
          </div>
          <div class="card-block border-bottom">
            <div class="row  align-items-center">
              <div class="col-8">
                <h2 class=" m-0">19</h2>
                <span class="text-muted">Last Week 70%</span>
              </div>
              <div class="col-4 text-right">
                <h5 class="text-c-red f-w-400">25%</h5>
              </div>
            </div>
          </div>
          <div class="card-block">
            <div class="row  align-items-center">
              <div class="col-8">
                <h2 class=" m-0">19</h2>
                <span class="text-muted">Last Week 70%</span>
              </div>
              <div class="col-4 text-right">
                <h5 class="text-c-red f-w-400">25%</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-block px-0 py-3">
        <line-stat-chart />
      </div>
    </div>
  </div>
</template>

<script>
import PieCountStats from "../components/dashboard/PieCountStats";
import LineStatChart from "../components/dashboard/LineStatChart";
export default {
  name: "Dashboard",
  components: { LineStatChart, PieCountStats },
  data() {
    return {
      selected: null
    };
  },
  created() {
    this.$store
      .dispatch("assistance/countTicketPerType", {
        date: new Date(),
        date_interval: null
      })
      .then(() => {
        console.log("LOG");
      });
  }
};
</script>

<style scoped></style>
