<template>
  <li :class="activeClass">
    <router-link
      :to="{
        name: 'ticket.list',
        params: { type_tickets_id: ticketType.id }
      }"
      >{{ ticketType.label }}</router-link
    >
  </li>
</template>

<script>
export default {
  name: "SubMenuTicketLine",
  props: {
    ticketType: {
      type: Object,
      required: true
    }
  },

  computed: {
    activeClass() {
      return RegExp("ticket.list*").test(this.$route.name) &&
        parseInt(this.$route.params.type_tickets_id) === this.ticketType.id
        ? "active pcoded-trigger"
        : "";
    }
  }
};
</script>

<style scoped></style>
