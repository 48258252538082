<template>
  <div class="form-group">
    <label v-if="label">{{ label }}</label>
    <div class="input-group">
      <input
        v-on="listeners"
        v-bind="$attrs"
        :type="isVisible ? 'text' : 'password'"
        class="form-control"
        :value="value"
        :class="{ 'is-invalid': isInvalid }"
        @input="updateValue"
      />
      <div class="input-group-append">
        <button
          data-toggle="tooltip"
          data-placement="top"
          :title="
            isVisible ? 'Masquer le mot de passe' : 'Voir le mot de passe'
          "
          @click.prevent="setVisible"
          class="btn btn-secondary"
          type="button"
        >
          <i class="fa mr-0" :class="isVisible ? 'fa-eye-slash' : 'fa-eye'"></i>
        </button>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import { formFieldMixin } from "../../mixins/formFieldMixin";
export default {
  name: "BaseInputPassword",
  mixins: [formFieldMixin],
  data() {
    return {
      isVisible: false
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue
      };
    }
  },
  methods: {
    setVisible() {
      this.isVisible = !this.isVisible;
    }
  }
};
</script>

<style scoped></style>
