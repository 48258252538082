import Dashboard from "../views/Dashboard";
import profile from "./profile";
import ticket from "./ticket";
import search from "./search";

const account = [
  {
    path: "/",
    redirect: { name: "dashboard" }
  },
  {
    path: "dashboard",
    name: "dashboard",
    component: Dashboard
  },
  {
    path: "profile",
    component: () => import("../views/profile/Profile"),
    children: profile
  },
  {
    path: "tickets",
    component: () => import("../views/ticket/TicketPage"),
    children: ticket
  },
  {
    path: "search",
    component: () => import("../views/search/SearchPage"),
    children: search
  }
];

export default account;
