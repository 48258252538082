<template>
  <div class="input-group">
    <input
      :disabled="disabled"
      type="text"
      class="form-control"
      :value="value"
      @input="onInput"
      placeholder="Rechercher par mot clé"
      @keypress.enter="onSearch"
    />
    <span
      v-if="value"
      @click="cancelSearch"
      title="Annuler"
      class="feather rounded f-20 icon-x mt-2 text-danger"
      style="margin-left: -25px; z-index: 10; cursor: pointer"
    ></span>
  </div>
</template>

<script>
export default {
  name: "BaseInputSearch",
  props: {
    value: {
      type: [String, Number]
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onInput(event) {
      this.$emit("input", event.target.value);
    },
    onSearch() {
      this.$emit("search");
    },
    cancelSearch() {
      this.$emit("cancel");
    }
  },
  beforeDestroy() {
    if (this.value) this.$emit("cancel");
  }
};
</script>

<style scoped></style>
