import axios from "axios";
import { getPasswordResetRoute, getVerifyEmailRoute } from "../helpers/utils";

export default {
  login(credentials) {
    return axios.post("/login", credentials);
  },

  logout() {
    return axios.get("/logout");
  },

  register(credentials) {
    return axios.post("/register", {
      ...credentials,
      verify_link: getVerifyEmailRoute()
    });
  },

  resendVerifyEmail() {
    return axios.post("/verify-email", {
      verify_link: getVerifyEmailRoute()
    });
  },

  confirmVerifyEmail(payload) {
    return axios.get(`verify-email/${payload.token}`);
  },

  resetPasswordLink(payload) {
    return axios.post("/reset-password-web", {
      ...payload,
      reset_link: getPasswordResetRoute()
    });
  },

  changePassword(payload) {
    return axios.post("/change-password-web", payload);
  },

  sendPhoneConfirmationCode(phone, web = null) {
    return axios.get(`/resend-confirmation-code/${phone}${web ? "/web" : ""}`);
  },

  confirmPhoneNumber(payload) {
    return axios.post("/confirm-phone-number", payload);
  },

  updateUser(user) {
    return axios.put("/users/" + user.id, user);
  },

  updateUserPreference(payload) {
    return axios.post("/users/newsletters", payload);
  },

  updatePassword(payload) {
    return axios.post("/users/change-password", payload);
  },

  getCurrentUser() {
    return axios.get("/user");
  },

  searchUserByEmail(email) {
    return axios.post("/users/list", { search: true, email: email });
  },

  getMovements(user_id, page = 1, paginate = 10) {
    return axios.get(
      `/users/${user_id}/mouvements?page=${page}&paginate=${paginate}`
    );
  },

  getNotifications(page = 1) {
    return axios.get(`/users/notifications/all?page=${page}`);
  }
};
