import AssistanceService from "../../services/AssistanceService";
import OperatorService from "../../services/OperatorService";

const state = {
  metaTicket: null,
  ticketFieldPage: 1,
  ticketFieldChange: false,
  ticketField: {
    country_id: null,
    operators_id: null,
    type_tickets_id: null,
    api_code: null,
    date: null,
    status: null,
    state: null,
    date_interval: null,
    keyword: null,
    is_read: false,
    show_all: false,
    is_operation_date: false,
    order_by: "tickets.updated_at",
    direction: "DESC",
    paginate: 10,
    close_all: false
  },
  userTicketModel: null,
  ticket_types: [],
  operators: [],
  api_list: [],
  ticket: null,
  savedMessages: [],
  ticketStats: {
    count: null,
    monthly: null
  }
};

// getters
const getters = {
  tickets: state => (state.metaTicket ? state.metaTicket.data : []),
  userTickets: state =>
    state.userTicketModel
      ? state.userTicketModel.data.filter(t => t.is_active)
      : [],
  current_page: state => (state.metaTicket ? state.metaTicket.current_page : 1),
  last_page: state => (state.metaTicket ? state.metaTicket.last_page : 1),
  total: state => (state.metaTicket ? state.metaTicket.total : 1),
  getTypeTicketSavedMessages: state => type_tickets_id =>
    state.savedMessages.filter(
      sm =>
        sm.type_tickets_id === type_tickets_id || sm.type_tickets_id === null
    )
};

// privileges
const actions = {
  getTicketList({ commit, state, dispatch }, force = false) {
    if (
      !force &&
      state.metaTicket &&
      state.ticketFieldPage === state.metaTicket.current_page &&
      !state.ticketFieldChange
    )
      return state.metaTicket;
    else {
      return AssistanceService.getTickets(
        state.ticketFieldPage,
        state.ticketField
      ).then(({ data }) => {
        if (data.message) {
          const notification = {
            type: "success",
            message: data.message,
            is_toast: true
          };
          dispatch("notification/add", notification, { root: true });
        }
        if (state.ticketField.close_all) {
          commit("CLOSE_ALL_TICKETS");
          commit("UPDATE_FIELD_CLOSE_ALL", false);
        } else {
          commit("SET_META_TICKET", data);
          commit("UPDATE_FIELD_DATE", null);
          commit("UPDATE_FIELD_DATE_INTERVAL", null);
          commit("UPDATE_TICKET_FIELD_CHANGE", false);
        }
        return data;
      });
    }
  },
  getUserTicketList({ commit, state }, { user_id, ticket_id }) {
    if (state.userTicketModel && state.userTicketModel.users_id === user_id)
      return state.userTicketModel;
    else {
      return AssistanceService.getUserOtherTickets(user_id, ticket_id).then(
        ({ data }) => {
          commit("SET_USER_TICKETS_MODEL", { users_id: user_id, data });
          return data;
        }
      );
    }
  },
  getTicket({ state, commit, dispatch }, reference) {
    if (state.ticket && state.ticket.reference.toLowerCase() === reference)
      return state.ticket;
    else {
      return AssistanceService.getTicketByReference(reference)
        .then(({ data }) => {
          commit("SET_TICKET", data);
          return data;
        })
        .catch(err => {
          if (err.response.data.message) {
            const notification = {
              type: "danger",
              message: err.response.data.message,
              is_toast: true
            };
            dispatch("notification/add", notification, { root: true });
          }
          return Promise.reject(err.response.data);
        });
    }
  },
  verifyTicketOperationState({ dispatch, commit }, ticket) {
    return AssistanceService.verifyTicketOperationState(ticket.id)
      .then(({ data }) => {
        if (data.message && data.status) {
          commit("SET_TICKET_OPERATION_STATE", { ticket, status: data.status });
          const notification = {
            type: "info",
            message: data.message,
            is_toast: true
          };
          dispatch("notification/add", notification, { root: true });
        }
        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: true
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  },
  getTicketTypeList({ state, commit }) {
    if (state.ticket_types.length > 0) return state.ticket_types;
    else
      return AssistanceService.getTicketTypes().then(({ data }) => {
        commit("SET_TICKET_TYPES", data);
        return data;
      });
  },
  getAllOperators({ state, commit }, { country_id }) {
    if (state.operators.length > 0) return state.operators;
    else
      return OperatorService.getAllOperators({ country_id }).then(
        ({ data }) => {
          commit("SET_ALL_OPERATORS", data);
          return data;
        }
      );
  },
  getApiList({ state, commit }) {
    if (state.api_list.length > 0) return state.api_list;
    else
      return OperatorService.getAPIConfigurationList().then(({ data }) => {
        commit("SET_API_LIST", data);
        return data;
      });
  },

  sendNewMessage({ commit, dispatch }, { message, ticket }) {
    return AssistanceService.addTicketMessage({
      tickets_id: ticket.id,
      message
    })
      .then(({ data }) => {
        commit("ADD_TICKET_MESSAGE", { ticket, data });
        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: true
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  },

  closeTicket({ commit, dispatch }, ticket) {
    return AssistanceService.updateTicket({ ...ticket, is_active: false })
      .then(({ data }) => {
        commit("REMOVE_TICKET", ticket);
        if (data.message) {
          const notification = {
            type: "info",
            message: data.message,
            is_toast: true
          };
          dispatch("notification/add", notification, { root: true });
        }
        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: true
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  },
  closeAllTicketInList({ state, dispatch }, { page, users_id }) {
    return AssistanceService.getTickets(page, {
      ...state.ticketField,
      close_all: true,
      paginate: page ? state.ticketField.paginate : null,
      users_id
    })
      .then(({ data }) => {
        if (data.message) {
          const notification = {
            type: "success",
            message: data.message,
            is_toast: true
          };
          dispatch("notification/add", notification, { root: true });
        }
        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: true
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  },
  getSavedMessageList({ state, commit, dispatch }, field) {
    if (state.savedMessages.length > 0) return state.savedMessages;
    else
      return AssistanceService.getTicketSavedMessages(field)
        .then(({ data }) => {
          commit("SET_SAVED_MESSAGES", data);
          return data;
        })
        .catch(err => {
          if (err.response.data.message) {
            const notification = {
              type: "danger",
              message: err.response.data.message,
              is_toast: true
            };
            dispatch("notification/add", notification, { root: true });
          }
          return Promise.reject(err.response.data);
        });
  },
  createSavedMessage({ commit, dispatch }, savedMessage) {
    return AssistanceService.addTicketSavedMessage(savedMessage)
      .then(({ data }) => {
        commit("ADD_SAVED_MESSAGE", data);
        if (data.message) {
          const notification = {
            type: "success",
            message: data.message,
            is_toast: true
          };
          dispatch("notification/add", notification, { root: true });
        }
        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: true
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  },
  updateSavedMessage({ commit, dispatch }, savedMessage) {
    return AssistanceService.updateTicketSavedMessage(savedMessage)
      .then(({ data }) => {
        if (data.message) {
          const notification = {
            type: "success",
            message: data.message,
            is_toast: true
          };
          dispatch("notification/add", notification, { root: true });
        }
        commit("UPDATE_SAVED_MESSAGE", savedMessage);
        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: true
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  },
  deleteSavedMessage({ commit, dispatch }, savedMessageId) {
    return AssistanceService.deleteTicketSavedMessage(savedMessageId)
      .then(({ data }) => {
        if (data.message) {
          const notification = {
            type: "success",
            message: data.message,
            is_toast: true
          };
          dispatch("notification/add", notification, { root: true });
        }
        commit("DELETE_SAVED_MESSAGE", savedMessageId);
        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: true
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  },
  countTicketPerType({ commit }, { date_interval, date }) {
    return AssistanceService.countPerTypes({ date_interval, date }).then(
      ({ data }) => {
        commit("SET_COUNT_PER_TYPE", data);
        return data;
      }
    );
  },

  updateTicketFormValue({ commit, dispatch }, ticketFormValue) {
    return AssistanceService.updateTicketFormValue(ticketFormValue)
      .then(({ data }) => {
        if (data.message) {
          const notification = {
            type: "success",
            message: data.message,
            is_toast: true
          };
          dispatch("notification/add", notification, { root: true });
        }
        commit("UPDATE_TICKET_FORM_VALUE", ticketFormValue);
        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: true
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  },

  confirmTicketNumber({ dispatch, commit }, ticket) {
    return AssistanceService.confirmTicketNumber(ticket.id)
      .then(({ data }) => {
        commit("REMOVE_TICKET", ticket);
        if (data.message) {
          const notification = {
            type: "info",
            message: data.message,
            is_toast: true
          };
          dispatch("notification/add", notification, { root: true });
        }
        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: true
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  }
};

// mutations
const mutations = {
  SET_META_TICKET(state, metaTicket) {
    state.metaTicket = metaTicket;
  },
  SET_TICKET(state, ticket) {
    state.ticket = ticket;
  },
  REMOVE_TICKET(state, ticket) {
    ticket.is_active = false;
    ticket.is_read = true;
    if (state.metaTicket) {
      state.metaTicket.data = state.metaTicket.data.filter(
        t => t.id !== ticket.id
      );
      state.metaTicket.total -= 1;
    }
    if (state.userTicketModel) {
      state.userTicketModel.data = state.userTicketModel.data.filter(
        t => t.id !== ticket.id
      );
    }
  },
  CLOSE_ALL_TICKETS(state) {
    if (state.metaTicket) {
      state.metaTicket.data = [];
      state.metaTicket.total = 1;
      state.metaTicket.current_page = 1;
      state.metaTicket.last_page = 1;
    }
  },
  REMOVE_USER_TICKETS(state, ticket) {
    ticket.is_active = false;
    ticket.is_read = true;
    if (state.metaTicket) {
      state.metaTicket.data = state.metaTicket.data.filter(
        t => t.user.id !== ticket.user.id
      );
      state.metaTicket.total = state.metaTicket.data.length;
    }
    if (state.userTicketModel) {
      state.userTicketModel.data = state.userTicketModel.data.filter(
        t => t.user.id !== ticket.user.id
      );
    }
  },
  SET_USER_TICKETS_MODEL(state, userTicketModel) {
    state.userTicketModel = userTicketModel;
  },
  SET_TICKET_OPERATION_STATE(state, { ticket, status }) {
    ticket.model.status = status;
  },

  SET_TICKET_TYPES(state, ticket_types) {
    state.ticket_types = ticket_types;
  },
  SET_ALL_OPERATORS(state, operators) {
    state.operators = operators;
  },
  SET_API_LIST(state, api_list) {
    state.api_list = api_list;
  },
  ADD_TICKET_MESSAGE(state, { ticket, data }) {
    ticket.messages.push(data);
  },

  UPDATE_FIELD_COUNTRY_ID(state, country_id) {
    state.ticketField.country_id = country_id;
    state.ticketField.operators_id = null;
    state.ticketFieldChange = true;
  },
  UPDATE_FIELD_OPERATORS_ID(state, operators_id) {
    state.ticketField.operators_id = operators_id;
    state.ticketFieldChange = true;
  },
  UPDATE_FIELD_TYPE_TICKETS_ID(state, type_tickets_id) {
    if (state.ticketField.type_tickets_id !== type_tickets_id) {
      state.ticketField.type_tickets_id = type_tickets_id;
      if (type_tickets_id === null) {
        state.ticketField.operators_id = null;
        state.ticketField.status = null;
        state.ticketField.state = null;
        state.ticketField.api_code = null;
      }
      state.ticketFieldChange = true;
      state.ticketFieldPage = 1;
    }
  },
  UPDATE_FIELD_USERS_ID(state, users_id) {
    if (state.ticketField.users_id !== users_id) {
      state.ticketField.users_id = users_id;
      state.ticketFieldChange = true;
    }
  },
  UPDATE_FIELD_API_CODE(state, api_code) {
    state.ticketField.api_code = api_code;
    state.ticketFieldChange = true;
  },
  UPDATE_FIELD_DATE(state, date) {
    state.ticketField.date = date;
    state.ticketFieldChange = true;
  },
  UPDATE_FIELD_STATUS(state, status) {
    state.ticketField.status = status;
    state.ticketFieldChange = true;
  },
  UPDATE_FIELD_STATE(state, value) {
    state.ticketField.state = value;
    state.ticketFieldChange = true;
  },
  UPDATE_FIELD_DATE_INTERVAL(state, date_interval) {
    state.ticketField.date_interval = date_interval;
    state.ticketFieldChange = true;
  },
  UPDATE_FIELD_IS_OPERATION_DATE(state, is_operation_date) {
    state.ticketField.is_operation_date = is_operation_date;
    state.ticketFieldChange = true;
  },
  UPDATE_FIELD_KEYWORD(state, keyword) {
    state.ticketField.keyword = keyword;
    state.ticketFieldChange = true;
  },
  UPDATE_FIELD_IS_READ(state, is_read) {
    state.ticketField.is_read = is_read;
    state.ticketFieldChange = true;
  },
  UPDATE_FIELD_SHOW_ALL(state, show_all) {
    state.ticketField.show_all = show_all;
    state.ticketFieldChange = true;
  },
  UPDATE_FIELD_ORDER_BY(state, order_by) {
    state.ticketField.order_by = order_by;
    state.ticketFieldChange = true;
  },
  UPDATE_FIELD_DIRECTION(state, direction) {
    state.ticketField.direction = direction;
    state.ticketFieldChange = true;
  },
  UPDATE_FIELD_PAGINATE(state, paginate) {
    state.ticketField.paginate = paginate;
    state.ticketFieldChange = true;
    state.ticketFieldPage = 1;
  },
  UPDATE_FIELD_CLOSE_ALL(state, close_all) {
    state.ticketField.close_all = close_all;
    state.ticketFieldChange = true;
  },
  UPDATE_TICKET_FIELD_PAGE(state, ticketFieldPage) {
    state.ticketFieldPage = ticketFieldPage;
  },
  UPDATE_TICKET_FIELD_CHANGE(state, ticketFieldChange) {
    state.ticketFieldChange = ticketFieldChange;
  },

  SET_SAVED_MESSAGES(state, savedMessages) {
    state.savedMessages = savedMessages;
  },
  ADD_SAVED_MESSAGE(state, savedMessage) {
    state.savedMessages.unshift(savedMessage);
  },
  UPDATE_SAVED_MESSAGE(state, savedMessage) {
    const messageIndex = state.savedMessages.findIndex(
      sm => sm.id === savedMessage.id
    );
    if (messageIndex >= 0)
      state.savedMessages.splice(messageIndex, 1, savedMessage);
  },
  DELETE_SAVED_MESSAGE(state, savedMessageId) {
    state.savedMessages = state.savedMessages.filter(
      sm => sm.id !== savedMessageId
    );
  },
  SET_COUNT_PER_TYPE(state, data) {
    state.ticketStats.count = data;
  },
  UPDATE_TICKET_FORM_VALUE(state, ticketFormValue) {
    if (state.ticket) {
      const formValueIndex = state.ticket.ticket_form_values.findIndex(
        tfv => tfv.id === ticketFormValue.id
      );
      if (formValueIndex >= 0)
        state.ticket.ticket_form_values.splice(
          formValueIndex,
          1,
          ticketFormValue
        );
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
