<template>
  <header
    class="navbar headerpos-fixed pcoded-header navbar-expand-lg navbar-light"
  >
    <div class="m-header">
      <a class="mobile-menu" id="mobile-collapse1" href="#"><span></span></a>
      <a href="/" class="b-brand">
        <img src="../../assets/images/logo/logo_light.png" class="img-fluid" />
      </a>
    </div>
    <a class="mobile-menu" id="mobile-header" href="#">
      <i class="feather icon-more-horizontal"></i>
    </a>
    <div class="collapse navbar-collapse">
      <ul class="navbar-nav mr-auto">
        <li class="nav-link d-block d-sm-none">
          <b class="ml-3 f-w-300 f-18" :class="balanceColor">
            <i class="fa fa-wallet"></i>
            {{ user.stats.balance | numFormat }} CFA
          </b>
        </li>
      </ul>
      <ul class="navbar-nav ml-auto">
        <li class="pl-0 pl-sm-1">
          <div class="dropdown drp-user">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown">
              <span class="m-r-10">{{ userRoleName }}</span>
              <i class="icon feather icon-settings f-20"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right profile-notification">
              <div class="pro-head">
                <span class="text-capitalize">{{
                  user.nom + " " + user.prenom
                }}</span>
                <a
                  href="#"
                  @click.prevent="logout"
                  class="dud-logout"
                  title="Logout"
                >
                  <i class="feather icon-log-out"></i>
                </a>
              </div>
              <ul class="pro-body">
                <li :class="{ active: $route.name === 'profile' }">
                  <router-link :to="{ name: 'profile' }" class="dropdown-item"
                    ><i class="feather icon-user"></i> Mon Profil</router-link
                  >
                </li>
                <li :class="{ active: $route.name === 'formPassword' }">
                  <router-link
                    :to="{ name: 'formPassword' }"
                    class="dropdown-item"
                    ><i class="feather icon-settings"></i> Mot de
                    passe</router-link
                  >
                </li>
                <li>
                  <a
                    @click.prevent="logout"
                    href="#"
                    class="dropdown-item text-danger"
                    ><i class="feather icon-log-out"></i> Déconnexion</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "Header",
  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("auth", ["balanceColor"]),
    userRoleName() {
      if (this.user) {
        return this.user.role
          ? this.user.role.name
          : this.user.is_admin
          ? "Super Admin"
          : "Assistance";
      } else return null;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        location.assign("/login");
      });
    }
  }
};
</script>

<style scoped></style>
