<template>
  <div class="text-center p-2">
    <img :src="emptyImage" class="empty-icon img-fluid" />
    <em
      class="d-block f-w-300 f-16 pb-3"
      :class="isAssistance ? 'text-white' : 'text-muted'"
      >{{ message }}
    </em>
  </div>
</template>

<script>
import { emptyListModel } from "../../helpers/constants";

export default {
  name: "BaseEmptyList",
  props: {
    model: {
      type: String,
      default: emptyListModel.empty
    },
    search: {
      type: Boolean,
      default: false
    },
    keyword: {
      type: String,
      default: null
    }
  },
  computed: {
    currentModel() {
      switch (this.model) {
        case emptyListModel.disableWithdraw:
          return emptyListModel.withdraw;
        case emptyListModel.disableDeposit:
          return emptyListModel.deposit;
        default:
          return this.model;
      }
    },
    emptyImage() {
      return `/assets/images/${this.currentModel}.svg`;
    },

    isAssistance() {
      return this.model === emptyListModel.assistance;
    },

    message() {
      switch (this.model) {
        case emptyListModel.movement:
          return "Cet utilisateur n'a effectué aucune opération valide pour le moment.";
        case emptyListModel.deposit:
          return this.search
            ? `Nous n'avons trouvé aucun dépôt contenant le mot clé ${this.keyword}`
            : "Commencer par recharger votre compte.";
        case emptyListModel.withdraw:
          return this.search
            ? `Nous n'avons trouvé aucun retrait contenant le mot clé ${this.keyword}`
            : "Vous n'avez effectué aucun retrait d'argent.";
        case emptyListModel.transfer:
          return this.search
            ? `Nous n'avons trouvé aucun transfert contenant le mot clé ${this.keyword}`
            : "Vous n'avez effectué aucun transfert vers un autre compte.";
        case emptyListModel.shopping:
          return this.search
            ? `Nous n'avons trouvé aucun achat de service contenant le mot clé ${this.keyword}`
            : "Vous n'avez acheté aucun service BeWallet pour le moment.";
        case emptyListModel.payment:
          return this.search
            ? `Nous n'avons trouvé aucun paiement contenant le mot clé ${this.keyword}`
            : "Aucun paiement n'a été réçu pour le moment.";
        case emptyListModel.assistance:
          return "Vous n'avez aucun ticket en cours.";
        case emptyListModel.message:
          return "Vous n'avez pas de messages";
        case emptyListModel.disableWithdraw:
          return "Les retraits ne sont pas disponibles pour le moment !";
        case emptyListModel.disableDeposit:
          return "Les dépôts ne sont pas disponibles pour le moment !";
        default:
          return "";
      }
    }
  }
};
</script>

<style>
.empty-icon {
  max-width: 300px;
}
</style>
