export default {
  fields: {
    phone: {
      label: "Phone",
      placeholder: "Enter valid phone number"
    },
    email: {
      label: "E-mail",
      placeholder: "Enter the e-mail"
    },
    password: {
      label: "Password",
      placeholder: "Enter the password"
    },
    prenom: {
      label: "First name",
      placeholder: "Enter the first name"
    },
    nom: {
      label: "Last name",
      placeholder: "Enter the last name"
    },
    otp: {
      label: "One Time Password",
      placeholder: "Enter the one time password"
    },
    country: {
      label: "Country",
      placeholder: "Choisir the Country"
    },
    operator: {
      label: "Operator",
      placeholder: "Choose the operator",
      empty: "No operator available"
    },
    amount: {
      label: "Amount",
      placeholder: "Enter the amount"
    },
    reference: {
      label: "Reference",
      placeholder: "Enter the reference"
    },
    website: {
      label: "Website",
      placeholder: "Enter the website url"
    }
  },
  countries: {
    cm: "Cameroon",
    bj: "Benin",
    ga: "Gabon",
    gh: "Ghana",
    gn: "Guinea",
    ci: "Ivory Coast",
    ml: "Mali",
    sn: "Senegal",
    tg: "Togo",
    bf: "Burkina Faso"
  }
};
