export const typeServiceCode = {
  voyage: "VOY",
  recharge: "REC",
  invoice: "FAC",
  subscription: "ABN",
  sell_card: "VCV",
  charge_card: "CDP"
};

export const typeTicketCode = {
  ...typeServiceCode,
  payment: "payment",
  withdraw: "withdraw",
  deposit: "deposit",
  other: "other",
  number: "numbers"
};

export const emptyListModel = {
  message: "message",
  shopping: "shopping",
  movement: "movement",
  deposit: "deposit",
  withdraw: "withdraw",
  disableWithdraw: "disableWithdraw",
  disableDeposit: "disableDeposit",
  transfer: "transfer",
  assistance: "assistance",
  payment: "payment"
};

export const statusValues = {
  success: "Succès",
  error: "Echec",
  pending: "En cours"
};

export const operationStatus = [
  { id: statusValues.success, label: "Succès" },
  { id: statusValues.error, label: "Echèc" },
  { id: statusValues.pending, label: "En cours" }
];

export const adminBaseUrl =
  process.env.NODE_ENV !== "production"
    ? "http://127.0.0.1:8000"
    : "https://api.be-wallet.net";

export const ticketStates = [{ id: "ABYSTER_GAB", label: "Ticket avec GAB" }];
