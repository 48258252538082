export default {
  maintenance: { label: "Maintenance", title: "Opération de Maintenance" },
  profile: { label: "Profil", title: "Configuration" },
  Login: { label: "Authentification", title: "Connexion" },
  Home: { label: "Accueil BeWallet", title: "BeWallet - Accueil" },
  Register: { label: "Enregistrement", title: "Création de compte" },
  EmailVerify: {
    label: "Confirmation",
    title: "Confirmation de l'adresse"
  },
  PasswordReset: {
    label: "Mot de passe oublié",
    title: "Mot de passe oublié"
  },
  PasswordChange: {
    label: "Changement du mot de passe",
    title: "Nouveau mot de passe"
  },
  formSetting: {
    label: "Préference",
    title: "Configuration du compte"
  },
  formPassword: {
    label: "Sécurité",
    title: "Modification du mot de passe"
  },
  dashboard: { label: "Tableau de bord", title: "Tableau de bord" },
  assistance: { label: "Demande d'assistance", title: "Centre d'assistance" },
  ticket: {
    list: {
      label: "Liste des tickets",
      title: "Liste des tickets"
    },
    detail: {
      label: "Détails tickets",
      title: "Consultation du ticket",
      message: {
        label: "Gestion des messages",
        title: "Messages auvegardés"
      }
    },
    user: {
      detail: {
        label: "Détails utilisateur",
        title: "Information sur le créateur du ticket"
      }
    }
  }
};
