const ticket = [
  {
    path: "/",
    redirect: { name: "ticket.list" }
  },
  {
    path: "bewallet",
    name: "search.bewallet",
    component: () => import("../views/search/BeWalletSearch")
  },
  {
    path: "betmomo",
    name: "search.betmomo",
    component: () => import("../views/search/BetmomoSearch")
  }
];

export default ticket;
