<template>
  <div class="alert" :class="notificationClass" v-if="!notification.is_toast">
    <button type="button" class="close" @click.prevent="remove(notification)">
      <span aria-hidden="true">×</span>
    </button>
    {{ notification.message }}
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "NotificationBar",
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      timeout: null
    };
  },
  mounted() {
    if (this.notification.is_toast) {
      switch (this.notification.type) {
        case "info":
        case "success":
          this.$toast.success(
            this.notification.message,
            this.notification.title,
            this.notificationSystem.options.success
          );
          break;
        case "danger":
        case "error":
          this.$toast.error(
            this.notification.message,
            this.notification.title,
            this.notificationSystem.options.error
          );
          break;
      }
      this.remove(this.notification);
    } else
      this.timeout = setTimeout(
        () => this.remove(this.notification),
        this.notification.timeout ? this.notification.timeout : 30000
      );
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  methods: mapActions("notification", ["remove"]),
  computed: {
    ...mapState("notification", ["notificationSystem"]),
    notificationClass() {
      return `alert-bwallet-${this.notification.type}`;
    }
  }
};
</script>

<style scoped></style>
