<template>
  <!-- [ breadcrumb ] start -->
  <div class="page-header mb-0 mb-sm-2">
    <div class="page-block">
      <div class="row align-items-center">
        <div class="col-md">
          <div class="page-header-title">
            <h5 class="m-b-10">{{ $t(`menu.${$route.name}.title`) }}</h5>
          </div>
          <ul class="breadcrumb">
            <template v-if="$route.name !== 'dashboard'">
              <li class="breadcrumb-item">
                <router-link
                  :to="{ name: 'dashboard' }"
                  :title="$t('menu.dashboard.title')"
                  ><i class="feather icon-home"></i
                ></router-link>
              </li>
              <li class="breadcrumb-item" v-if="prevRoute">
                <router-link
                  :to="{
                    name: prevRoute.name,
                    params: prevRoute.params,
                    query: prevRoute.query
                  }"
                  :title="$t(`menu.${prevRoute.name}.title`)"
                >
                  {{ $t(`menu.${prevRoute.name}.label`) }}
                </router-link>
              </li>
              <li class="breadcrumb-item">
                {{ $t(`menu.${$route.name}.label`) }}
              </li>
            </template>
          </ul>
        </div>
        <div class="col-md-auto text-right">
          <h4 class="f-w-300 text-capitalize">
            <i class="fas fa-user-tag"></i>
            {{ `${user.nom} ${user.prenom}` }}
          </h4>
          <span class="d-block">{{ user.email }}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- [ breadcrumb ] end -->
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "BreadCrumb",
  props: {
    prevRoute: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("auth", ["balanceColor"])
  }
};
</script>

<style scoped></style>
