<template>
  <div>
    <NotificationBar
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
      v-show="!notification.is_toast"
    />
  </div>
</template>

<script>
import NotificationBar from "./NotificationBar";
import { mapState } from "vuex";
export default {
  name: "NotificationContainer",
  components: { NotificationBar },
  computed: mapState("notification", ["notifications"])
};
</script>

<style scoped></style>
