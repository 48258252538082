<template>
  <div class="text-center">
    <div
      class="spinner-border"
      :class="`${textColor} ${isSmall ? 'spinner-border-sm' : ''}`"
      role="status"
    >
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseLoader",
  props: {
    textColor: {
      type: String,
      default: "text-info"
    },
    isSmall: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped></style>
