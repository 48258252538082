import axios from "axios";

import AuthService from "../../services/AuthService";
import { editCurrentUser } from "../helpers";
import { emptyListModel } from "../../helpers/constants";
import { getUserSession, setUserSession } from "../../helpers/utils";

const state = {
  user: null,
  metaNotification: null
};

// getters
const getters = {
  loggedIn: state => {
    return !!state.user;
  },
  isCompleteProfile: state => {
    return (
      state.user.phone &&
      state.user.nom &&
      state.user.prenom &&
      state.user.genre
    );
  },
  balanceColor: state => {
    if (state.user.stats.total < state.user.config.retrait_min)
      return "text-danger";
    else return "text-success";
  },
  isCurrentUser: state => id => {
    return state.user.id === id;
  },
  isCurrentUserMerchant: state => state.user.is_marchand,
  isCurrentUserSuperAdmin: state => {
    return state.user
      ? state.user.is_admin && state.user.roles_id === null
      : false;
  },
  calling_code: state => state.user.country.calling_code,
  phone: state => state.user.country.calling_code + " " + state.user.phone,
  country_id: state => state.user.country.id,
  userId: state => state.user.id,
  notifications: state => state.user.notifications
};

// privileges
const actions = {
  getCurrentUser({ commit }) {
    return AuthService.getCurrentUser().then(({ data }) => {
      commit("SET_CURRENT_USER", data);
      return data;
    });
  },
  login({ commit, dispatch }, credentials) {
    return AuthService.login(credentials)
      .then(({ data }) => {
        commit("SET_USER_TOKEN", { ...data, remember: credentials.remember });
        const notification = {
          type: "success",
          title: "Auth",
          message: "Authentification effectue avec success.",
          is_toast: true
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => Promise.reject(error));
  },
  register({ commit }, credentials) {
    return AuthService.register(credentials).then(({ data }) => {
      commit("SET_USER_TOKEN", data);
    });
  },
  resendVerifyEmail({ dispatch }) {
    return AuthService.resendVerifyEmail().then(({ data }) => {
      const notification = {
        type: "info",
        message: data.message,
        is_toast: true
      };
      dispatch("notification/add", notification, { root: true });
    });
  },
  confirmVerifyEmail({ commit, dispatch }, payload) {
    return AuthService.confirmVerifyEmail(payload)
      .then(({ data }) => {
        commit("VERIFY_USER_EMAIL");
        const notification = {
          type: "info",
          message: data.message,
          is_toast: true
        };
        dispatch("notification/add", notification, { root: true });
        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: true
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  },
  sendResetPasswordLink({ dispatch }, { email, recaptcha }) {
    return AuthService.resetPasswordLink({ email, recaptcha }).then(
      ({ data }) => {
        if (data.message) {
          const notification = {
            type: "info",
            message: data.message,
            is_toast: true
          };
          dispatch("notification/add", notification, { root: true });
        }
        return data.message;
      }
    );
  },
  resetPassword({ dispatch }, { token, recaptcha, password }) {
    return AuthService.changePassword({
      token,
      recaptcha,
      password,
      password_confirmation: password
    }).then(({ data }) => {
      if (data.message) {
        const notification = {
          type: "info",
          message: data.message,
          is_toast: false,
          timeout: 5000
        };
        dispatch("notification/add", notification, { root: true });
      }
      return data;
    });
  },
  logout({ commit }, service = true) {
    localStorage.removeItem("h_user_credentials");
    setTimeout(() => commit("CLEAR_USER_DATA"), 3000);
    if (service)
      return AuthService.logout().then(({ data }) => {
        return data;
      });
  },
  sendPhoneConfirmationCode({ dispatch }, { phone, web }) {
    return AuthService.sendPhoneConfirmationCode(phone, web).then(
      ({ data }) => {
        if (data.message) {
          const notification = {
            type: "info",
            message: data.message,
            is_toast: true
          };
          dispatch("notification/add", notification, { root: true });
        }
        return data;
      }
    );
  },
  confirmPhoneNumber({ commit, dispatch }, payload) {
    return AuthService.confirmPhoneNumber(payload).then(({ data }) => {
      commit("SET_USER_PHONE", payload.phone);
      if (data.message) {
        const notification = {
          type: "info",
          message: data.message,
          is_toast: true
        };
        dispatch("notification/add", notification, { root: true });
      }
    });
  },

  updateUser({ commit, dispatch }, user) {
    return AuthService.updateUser(user)
      .then(({ data }) => {
        commit("SET_USER", user);
        if (data.message) {
          const notification = {
            type: "info",
            message: data.message,
            is_toast: true
          };
          dispatch("notification/add", notification, { root: true });
        }
        return dispatch("getCurrentUser");
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: true
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  },

  updateUserPreference({ commit, dispatch }, payload) {
    return AuthService.updateUserPreference(payload.data).then(({ data }) => {
      switch (payload.element) {
        case "notifiable":
          commit("SET_USER_NOTIFIABLE", payload.data.notifiable);
          break;
        case "depot_notifiable":
          commit("SET_USER_DEPOT_NOTIFIABLE", payload.data.depot_notifiable);
          break;
      }
      if (data.message) {
        const notification = {
          type: "info",
          message: data.message,
          is_toast: true
        };
        dispatch("notification/add", notification, { root: true });
      }
      return data;
    });
  },

  changeUserPassword({ dispatch }, payload) {
    return AuthService.updatePassword(payload).then(({ data }) => {
      if (data.message) {
        const notification = {
          type: "info",
          message: data.message,
          is_toast: true
        };
        dispatch("notification/add", notification, { root: true });
      }
      return data;
    });
  },

  getNotifications({ commit, state }, page) {
    if (state.metaNotification && page === state.metaNotification.current_page)
      return state.metaNotification;
    else {
      return AuthService.getNotifications(page).then(({ data }) => {
        commit("SET_META_NOTIFICATION", data);
        if (state.user.notifications.length > 0) {
          commit("REMOVE_USER_NOTIFICATION");
          commit("SET_CURRENT_USER", state.user);
        }
      });
    }
  },

  markNotificationAsRead({ state, getters, commit }) {
    if (getters.notifications.length > 0) {
      commit("MARK_NOTIFICATION_READ");
      commit("SET_CURRENT_USER", state.user);
    }
  },

  receiveBroadcastNotification({ state, dispatch }, notify) {
    if (notify.type === "broadcast.transaction") {
      if (notify.message) {
        const notification = {
          type: "info",
          message: notify.message,
          is_toast: true
        };
        dispatch("notification/add", notification, { root: true });
      }
      switch (notify.operation) {
        case emptyListModel.deposit:
          dispatch(
            "deposit/getDepositList",
            {
              page: 1,
              cancel: true,
              field: { users_id: state.user.id, paginate: 10, search: false }
            },
            { root: true }
          );
          dispatch("getCurrentUser");
          break;
        case emptyListModel.withdraw:
          dispatch(
            "withdraw/getWithdrawList",
            {
              page: 1,
              cancel: true,
              field: { users_id: state.user.id, paginate: 10, search: false }
            },
            { root: true }
          );
          break;
        case emptyListModel.transfer:
          dispatch(
            "transfer/getTransferList",
            {
              page: 1,
              cancel: true,
              field: { users_id: state.user.id, paginate: 10, search: false }
            },
            { root: true }
          );
          break;
        case emptyListModel.shopping:
          dispatch(
            "shopping/getShoppingList",
            {
              page: 1,
              cancel: true,
              field: {
                users_id: state.user.id,
                paginate: 10,
                search: false,
                type: null
              }
            },
            { root: true }
          );
          break;
      }
    }
  }
};

// mutations
const mutations = {
  SET_USER_TOKEN(state, userData) {
    state.user = userData.user;
    setUserSession("h_user_credentials", userData);
    axios.defaults.headers.common["Authorization"] = `Bearer ${userData.token}`;
  },
  SET_CURRENT_USER(state, user) {
    let userData = getUserSession("h_user_credentials");
    userData.user = user;
    state.user = user;
    setUserSession("h_user_credentials", userData);
  },
  SET_USER(state, user) {
    state.user = user;
    editCurrentUser(state.user);
  },

  CLEAR_USER_DATA(state) {
    state.user = null;
  },

  VERIFY_USER_EMAIL(state) {
    if (state.user) {
      state.user.email_verified_at = new Date().toDateString();
      editCurrentUser(state.user);
    }
  },

  SET_USER_PHONE(state, phone) {
    state.user.phone = phone;
    editCurrentUser(state.user);
  },

  SET_USER_NOTIFIABLE(state, value) {
    state.user.notifiable = value;
    editCurrentUser(state.user);
  },

  SET_USER_DEPOT_NOTIFIABLE(state, value) {
    state.user.depot_notifiable = value;
    editCurrentUser(state.user);
  },

  MARK_NOTIFICATION_READ(state) {
    state.user.notifications.map(n => (n.is_read = true));
  },
  SET_META_NOTIFICATION(state, metaNotification) {
    state.metaNotification = metaNotification;
  },
  REMOVE_USER_NOTIFICATION(state) {
    state.user.notifications = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
