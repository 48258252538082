import menu from "./menu";

export default {
  menu,
  fields: {
    phone: {
      label: "Téléphone",
      placeholder: "Entrez le numéro de téléphone"
    },
    email: {
      label: "Email",
      placeholder: "Entrez l'email"
    },
    password: {
      label: "Mot de passe",
      placeholder: "Entrez le mot de passe"
    },
    prenom: {
      label: "Prénom",
      placeholder: "Entrez le prénom"
    },
    nom: {
      label: "Nom",
      placeholder: "Entrez le nom"
    },
    otp: {
      label: "Code de paiement",
      placeholder: "Entrez le code du paiement"
    },
    country: {
      label: "Pays",
      placeholder: "Choisir le Pays"
    },
    operator: {
      label: "Opérateur",
      placeholder: "Choisir l'opérateur",
      empty: "Aucun opérateur disponible"
    },
    amount: {
      label: "Montant",
      placeholder: "Entrez le montant"
    },
    reference: {
      label: "Référence",
      placeholder: "Entrez la référence"
    },
    website: {
      label: "Site web",
      placeholder: "Entrez l'url du site web"
    }
  },
  countries: {
    cm: "Cameroun",
    bj: "Bénin",
    ga: "Gabon",
    gh: "Ghana",
    gn: "Guinée",
    ci: "Côte d'Ivoire",
    ml: "Mali",
    sn: "Sénégal",
    tg: "Togo",
    bf: "Burkina Faso"
  }
};
