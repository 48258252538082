import { mapGetters } from "vuex";

export const assistanceMixin = {
  data() {
    return {
      loadingHelp: false,
      field: {
        paginate: 5,
        is_archive: false,
        is_active: true,
        users_id: null,
        order_by: "updated_at",
        direction: "DESC"
      }
    };
  },

  computed: {
    ...mapGetters("auth", ["country_id", "userId"])
  },

  methods: {
    getTicketList(page = 1, close = true) {
      this.loadingHelp = true;
      this.field.users_id = this.userId;
      this.$store
        .dispatch("assistance/getTicketList", {
          page: page,
          field: this.field
        })
        .then(() => {
          this.loadingHelp = false;
          if (close) window.$(".header-user-list").toggleClass("open");
        });
    },

    closeAssistance() {
      if (window.$(".pcoded-navbar").hasClass("mob-open")) {
        window.$(".pcoded-navbar").removeClass("mob-open");
        window.$("#mobile-collapse,#mobile-collapse1").removeClass("on");
      }
      window.$(".header-chat").removeClass("open");
      window.$(".header-user-list").removeClass("msg-open");
      window.$(".header-user-list").removeClass("open");
    }
  }
};
