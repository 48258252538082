<template>
  <nav class="pcoded-navbar menu-light menu-item-icon-style4">
    <div class="navbar-wrapper">
      <div class="navbar-brand header-logo">
        <a href="/" class="b-brand">
          <img src="../../assets/images/logo/logo_light.png" class="img-fluid"
        /></a>
      </div>
      <div class="navbar-content scroll-div">
        <ul class="nav pcoded-inner-navbar">
          <li
            data-username="Documentation"
            class="nav-item pb-1"
            :class="getRouteActiveClass('dashboard')"
          >
            <router-link :to="{ name: 'dashboard' }" class="nav-link">
              <span class="pcoded-micon"
                ><i class="feather icon-home"></i
              ></span>
              <span class="pcoded-mtext">Tableau de bord</span>
            </router-link>
          </li>
          <!--          <li class="nav-item pcoded-menu-caption pt-2 pb-0">-->
          <!--            <label class="m-l-20"-->
          <!--              ><span class="feather icon-search m-r-5"></span>Recherche</label-->
          <!--            >-->
          <!--          </li>-->
          <!--          <li-->
          <!--            data-username="Documentation"-->
          <!--            class="nav-item pb-1"-->
          <!--            :class="getRouteActiveClass('search.betmomo')"-->
          <!--          >-->
          <!--            <router-link :to="{ name: 'search.betmomo' }" class="nav-link">-->
          <!--              <span class="pcoded-micon"-->
          <!--                ><i class="feather icon-shopping-cart"></i-->
          <!--              ></span>-->
          <!--              <span class="pcoded-mtext">Recharge Betmomo</span>-->
          <!--            </router-link>-->
          <!--          </li>-->
          <!--          <li-->
          <!--            data-username="Documentation"-->
          <!--            class="nav-item pb-1"-->
          <!--            :class="getRouteActiveClass('search.bewallet')"-->
          <!--          >-->
          <!--            <router-link :to="{ name: 'search.bewallet' }" class="nav-link">-->
          <!--              <span class="pcoded-micon"-->
          <!--                ><i class="feather icon-minimize-2"></i-->
          <!--              ></span>-->
          <!--              <span class="pcoded-mtext">Recharge BeWallet</span>-->
          <!--            </router-link>-->
          <!--          </li>-->
          <li class="nav-item pcoded-menu-caption pt-2 pb-0">
            <label class="m-l-20"
              ><span class="feather icon-bell m-r-5"></span>Réclamations</label
            >
          </li>
          <li
            class="nav-item pcoded-hasmenu"
            :class="getRouteActiveClass('ticket', 'pcoded-trigger')"
            :style="getRouteActiveClass('ticket', 'display: block;')"
          >
            <a href="#" class="nav-link"
              ><span class="pcoded-micon"
                ><i class="feather feather icon-grid"></i></span
              ><span class="pcoded-mtext">Liste des tickets</span></a
            >
            <ul
              class="pcoded-submenu"
              :style="getRouteActiveClass('ticket', 'display: block;')"
            >
              <li :class="getRouteActiveClass('ticket', 'active', true)">
                <router-link :to="{ name: 'ticket.list' }" class="f-w-500"
                  >Tout afficher</router-link
                >
              </li>
              <SubMenuTicketLine
                v-for="ticketType in ticket_types"
                :key="ticketType.id"
                :ticket-type="ticketType"
              />
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { assistanceMixin } from "../../mixins/assistanceMixiin";
import { mapState } from "vuex";
import SubMenuTicketLine from "./SubMenuTicketLine";

export default {
  name: "Slider",
  components: { SubMenuTicketLine },
  mixins: [assistanceMixin],

  computed: {
    ...mapState("assistance", ["ticket_types"])
  },

  methods: {
    getRouteActiveClass(name, activeClass = "active", noParams = false) {
      if (noParams && this.$route.params.type_tickets_id) return "";
      return RegExp(name + "*").test(this.$route.name) ? activeClass : "";
    }
  }
};
</script>

<style scoped></style>
