import axios from "axios";

export default {
  getTickets(page, field) {
    return axios.post(`/tickets/list?page=${page}`, field);
  },
  getUserOtherTickets(ticket_id, user_id) {
    return axios.get(`/tickets/${ticket_id}/other-user/${user_id}/list`);
  },
  getTicketByReference(reference) {
    return axios.get(`/tickets/${reference}/details`);
  },
  getTicketTypes() {
    return axios.get("/tickets/types/list?help=true");
  },
  verifyTicketOperationState(ticket_id) {
    return axios.get(`/tickets/${ticket_id}/repointing`);
  },
  updateTicket(ticket) {
    return axios.put(`/tickets/${ticket.id}`, ticket);
  },
  addTicketMessage(payload) {
    return axios.post("/tickets/messages/store", payload);
  },

  getTicketSavedMessages(field) {
    return axios.post("/tickets/sauvegardes/list", field);
  },
  addTicketSavedMessage(payload) {
    return axios.post("/tickets/sauvegardes/store", payload);
  },
  updateTicketSavedMessage(savedMessage) {
    return axios.put(
      `/tickets/sauvegardes/${savedMessage.id}/update`,
      savedMessage
    );
  },
  deleteTicketSavedMessage(savedMessage_id) {
    return axios.delete(`/tickets/sauvegardes/${savedMessage_id}/destroy`);
  },

  countPerTypes(payload) {
    return axios.post("/tickets/count-per-types", payload);
  },
  monthlyStatPerTypes(payload) {
    return axios.get("/tickets/monthly/stats-per-types", payload);
  },

  updateTicketFormValue(ticketFormValue) {
    return axios.put(
      `/tickets/ticket-forms/values/${ticketFormValue.id}/update`,
      ticketFormValue
    );
  },

  confirmTicketNumber(ticket_id) {
    return axios.get(`/tickets/${ticket_id}/numbers/confirm`);
  }
};
