import axios from "axios";

export default {
  getAllOperators(payload) {
    return axios.post("/operators/list", payload);
  },

  getAPIConfigurationList() {
    return axios.get("/settings/api/list");
  }
};
