<template>
  <tr class="border-left">
    <td
      class="align-middle mt-1"
      :class="placeholder.text"
      v-for="(placeholder, index) in contentPlaceholders"
      :key="index"
    >
      <content-placeholders :centered="true" :rounded="true">
        <content-placeholders-text
          :lines="placeholder.lines"
          v-if="placeholder.isText"
        />
        <content-placeholders-img
          style="width:30px; height: 30px"
          v-if="!placeholder.isText"
        />
      </content-placeholders>
    </td>
  </tr>
</template>

<script>
export default {
  name: "BaseTableLineLoader",
  props: {
    operation: {
      type: String,
      default: "withdraw"
    }
  },

  data() {
    return {
      contentPlaceholders: []
    };
  },

  created() {
    switch (this.operation) {
      case "withdraw":
        for (let i = 0; i < 7; i++) {
          this.contentPlaceholders.push({
            ...this.getTextOrientation(i)
          });
        }
        break;
      case "deposit":
        for (let i = 0; i < 6; i++) {
          this.contentPlaceholders.push({
            ...this.getTextOrientation(i)
          });
        }
        break;
    }
  },

  methods: {
    getTextOrientation(i) {
      switch (this.operation) {
        case "withdraw":
          switch (i) {
            case 0:
              return { text: "text-center", isText: true, lines: 1 };
            case 1:
              return { text: "text-right", isText: true, lines: 2 };
            case 2:
              return { text: "text-left", isText: true, lines: 2 };
            case 3:
              return { text: "text-right", isText: true, lines: 2 };
            case 4:
              return { text: "text-center", isText: false, lines: null };
            case 5:
              return { text: "text-center", isText: true, lines: 1 };
            case 6:
              return { text: "text-center", isText: true, lines: 1 };
          }
          break;
        case "deposit":
          switch (i) {
            case 0:
              return { text: "text-center", isText: true, lines: 1 };
            case 1:
              return { text: "text-right", isText: true, lines: 2 };
            case 2:
              return { text: "text-left", isText: true, lines: 2 };
            case 3:
              return { text: "text-center", isText: false, lines: null };
            case 4:
              return { text: "text-center", isText: true, lines: 1 };
            case 5:
              return { text: "text-center", isText: true, lines: 1 };
          }
          break;
      }
    }
  }
};
</script>

<style scoped></style>
