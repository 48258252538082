<template>
  <div :class="label ? 'form-group' : ''">
    <label v-if="label">{{ label }}</label>
    <select
      v-on="listeners"
      v-bind="$attrs"
      :value="value"
      class="form-control"
      :class="{ 'is-invalid': isInvalid }"
    >
      <option :value="null" disabled>{{ placeholder }}</option>
      <option
        v-for="option in options"
        :key="idValue ? option[valueField] : option"
        :value="idValue ? option[valueField] : option"
        :selected="idValue ? option[valueField] === value : option === value"
        >{{ idValue ? option[showField] : option }}</option
      >
    </select>
    <slot />
  </div>
</template>

<script>
import { formFieldMixin } from "../../mixins/formFieldMixin";

export default {
  name: "BaseSelect",
  mixins: [formFieldMixin],
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      required: true
    },
    idValue: {
      type: Boolean,
      default: false
    },
    showField: {
      type: String,
      default: ""
    },
    valueField: {
      type: String,
      default: "id"
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue
      };
    }
  }
};
</script>

<style scoped></style>
