import Vue from "vue";
import VueRouter from "vue-router";
import account from "./account";
import NProgress from "nprogress";
import store from "@/store";
import authMiddleware from "../middleware/authMiddleware";
import Account from "../components/layouts/Account";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: { name: "dashboard" }
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/auth/Login"),
    meta: { redirectAuth: true }
  },
  {
    path: "/password-reset",
    name: "PasswordReset",
    component: () => import("../views/auth/PasswordReset"),
    meta: { redirectAuth: true }
  },
  {
    path: "/password/reset/:token",
    name: "PasswordChange",
    component: () => import("../views/auth/PasswordResetConfirm"),
    meta: { redirectAuth: true }
  },
  {
    path: "/email/verify/:token?",
    name: "EmailVerify",
    component: () => import("../views/auth/EmailVerify"),
    meta: { requiresAuth: true }
  },
  {
    path: "/app",
    component: Account,
    children: account,
    meta: { requiresAuth: true, requiresEmailVerification: true },
    beforeEnter(routeTo, routeFrom, next) {
      store
        .dispatch("assistance/getTicketTypeList")
        .then(() => next())
        .catch(err => {
          store.dispatch("notification/add", {
            type: "danger",
            message: err.response.data.message,
            is_toast: false
          });
          next();
        });
    }
  },
  {
    path: "/maintenance",
    name: "maintenance",
    component: () => import("../views/PageMaintenance")
  },
  {
    path: "/404",
    name: "pageNotFound",
    component: () => import("../views/PageNotFound")
  },
  {
    path: "**",
    redirect: { name: "pageNotFound" }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(authMiddleware);

router.afterEach(() => {
  NProgress.done();
  store.dispatch("stopRouterLoading");
});

export default router;
