<template>
  <ul class="pagination justify-content-center">
    <li class="page-item" :class="{ disabled: current_page - 1 <= 0 }">
      <a
        class="page-link text-secondary"
        :class="{ small: isSmall }"
        href="#"
        @click.prevent="onPageOpen(current_page - 1)"
      >
        <span class="d-none d-sm-inline">Précédent</span>
        <span class="d-sm-none d-inline"> Prec </span>
      </a>
    </li>
    <li class="page-item" v-if="current_page > 1">
      <a
        class="page-link2"
        :class="{ small: isSmall }"
        href="#"
        @click.prevent="onPageOpen(1)"
        >1</a
      >
    </li>
    <li class="page-item" v-if="current_page - 2 > 1">
      <a
        class="page-link2"
        :class="{ small: isSmall }"
        href="#"
        @click.prevent="onPageOpen(current_page - 2)"
        >{{ current_page - 2 }}</a
      >
    </li>
    <li class="page-item" v-if="current_page - 1 > 2">
      <a
        class="page-link2"
        :class="{ small: isSmall }"
        href="#"
        @click.prevent="onPageOpen(current_page - 1)"
        >{{ current_page - 1 }}</a
      >
    </li>
    <li class="page-item active" :class="'disabled'">
      <a class="page-link2" :class="{ small: isSmall }" href="#">{{
        current_page
      }}</a>
    </li>
    <li class="page-item" v-if="current_page + 1 < last_page">
      <a
        class="page-link2"
        :class="{ small: isSmall }"
        href="#"
        @click.prevent="onPageOpen(current_page + 1)"
        >{{ current_page + 1 }}</a
      >
    </li>
    <li class="page-item" v-if="current_page + 2 < last_page">
      <a
        class="page-link2"
        :class="{ small: isSmall }"
        href="#"
        @click.prevent="onPageOpen(current_page + 2)"
        >{{ current_page + 2 }}</a
      >
    </li>
    <li class="page-item" v-if="current_page !== last_page">
      <a
        class="page-link2"
        :class="{ small: isSmall }"
        href="#"
        @click.prevent="onPageOpen(last_page)"
        >{{ last_page }}</a
      >
    </li>
    <li class="page-item" :class="{ disabled: current_page + 1 > last_page }">
      <a
        class="page-link text-secondary"
        :class="{ small: isSmall }"
        href="#"
        @click.prevent="onPageOpen(current_page + 1)"
      >
        <span class="d-none d-sm-inline">Suivant</span>
        <span class="d-sm-none d-inline"> Suiv </span>
      </a>
    </li>
  </ul>
</template>
<script>
export default {
  name: "BasePagination",
  props: {
    last_page: {
      type: Number,
      required: true
    },
    current_page: {
      type: Number,
      required: true
    },
    isSmall: { type: Boolean, default: false }
  },

  methods: {
    onPageOpen(page) {
      this.$emit("page", page);
    }
  }
};
</script>

<style>
.page-link2 {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #3f4d67;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.small {
  padding: 0.3rem 0.45rem;
}
.page-item.active .page-link2 {
  color: #fff;
  background-color: #32849c;
  border-color: #32849c;
}
</style>
