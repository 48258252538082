import UserService from "../../services/UserService";

const state = {
  viewUser: null,
  metaMovements: [],
  lastMetaMovementRequest: null
};

// getters
const getters = {
  userStat: state => (state.viewUser ? state.viewUser.stats : null),
  getMovementList: state => limit => {
    let movements = [];
    state.metaMovements.forEach(
      mm =>
        (movements = [
          ...movements,
          ...(mm.data.length !== undefined ? mm.data : Object.values(mm.data))
        ])
    );
    if (limit)
      movements = movements.filter((m, index) => {
        return m && index < limit;
      });
    movements = movements.map((m, index, list) => {
      let amount = state.viewUser.stats.balance;
      list.forEach((movement, i) => {
        if (index > i) {
          if (movement.type === "Débit") amount += parseFloat(movement.amount);
          else amount -= parseFloat(movement.amount);
        }
      });
      return { ...m, solde: amount };
    });
    return movements;
  }
};

// privileges
const actions = {
  getViewUser({ state, commit, dispatch }, user_id) {
    if (state.viewUser && state.viewUser.id === user_id) return state.viewUser;
    return UserService.getUser(user_id)
      .then(({ data }) => {
        commit("SET_VIEW_USER", data);
        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: false
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  },
  getMovements({ state, commit, dispatch }, { page, force }) {
    const existMeta = state.metaMovements.find(mm => mm.current_page === page);
    if (existMeta !== undefined && !force) return existMeta;
    else
      return UserService.getMovements(state.viewUser.id, page, 30)
        .then(({ data }) => {
          commit("LAST_META_MOVEMENT_REQUEST", data);
          if (!force) commit("ADD_META_MOVEMENTS", data);
          return data;
        })
        .catch(err => {
          if (err.response.data.message) {
            const notification = {
              type: "danger",
              message: err.response.data.message,
              is_toast: false
            };
            dispatch("notification/add", notification, { root: true });
          }
          return Promise.reject(err.response.data);
        });
  },
  disableUserAccount({ dispatch, commit }, { userId, reason, ticket }) {
    return UserService.disableUserAccount(userId, reason)
      .then(({ data }) => {
        commit("assistance/REMOVE_USER_TICKETS", ticket, { root: true });
        const notification = {
          type: "info",
          message: data.message,
          is_toast: true
        };
        dispatch("notification/add", notification, { root: true });
        return data;
      })
      .catch(err => {
        if (err.response.data.message) {
          const notification = {
            type: "danger",
            message: err.response.data.message,
            is_toast: false
          };
          dispatch("notification/add", notification, { root: true });
        }
        return Promise.reject(err.response.data);
      });
  }
};

// mutations
const mutations = {
  SET_VIEW_USER(state, viewUser) {
    state.viewUser = viewUser;
    state.metaMovements = [];
    state.lastMetaMovementRequest = null;
  },
  ADD_META_MOVEMENTS(state, metaMovement) {
    state.metaMovements.push(metaMovement);
  },
  LAST_META_MOVEMENT_REQUEST(state, metaMovement) {
    state.lastMetaMovementRequest = metaMovement;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
